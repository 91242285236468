import React from 'react';
import HTMLFlipBook from 'react-pageflip';
import './FlipBook.css';
import './GrimLoreSection.css';
import ele from "./images/LoreBook/Elemental.png"
import dec from "./images/LoreBook/Decay.png"
import sac from "./images/LoreBook/Sacrificial.png"

const FlipBook = () => {

    return (
        <div className="flipbook-container">
            <HTMLFlipBook className="my-flipbook" width={1420 / 2} height={1050}>
                <div className="flip-page-odd" id="page1">\
                </div>
                <div className="flip-page-even" id="page2">
                    <div className="page-content">
                        <h1 className="text-xl text-center">GRIM BESTIARY</h1>
                        <p className="main-page" id="mainPage">
                            The GRIM are the worst of the worst across the multiverse of Earths that exist. Some of
                            these realms are laced with ethereal magic and must be protected from those that seek to
                            destroy. With the help of the Summoning Circles we will be able to view into these
                            worlds and find the most ruthless of them all. Removing the strongest among them could
                            save these worlds from total devastation.
                        </p>
                    </div>
                </div>
                <div className="flip-page-odd" id="page3">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Elemental Circle</h1>
                        <img className="ele-image" alt='elemental' src={ele} />
                        <p className="mt text-center" id="app-count-elemental"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page4">
                    <div className="page-content">
                        <p className="circles-font">
                            Known for harnessing their power through the energy and materials in their surroundings. Using the elements of
                            nature to create different types of devastating spells.
                        </p>
                    </div>
                </div>
                <div className="flip-page-odd" id="page5">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Circle of Decay</h1>
                        <img className="dec-image" alt='decay' src={dec} />
                        <p className="mt text-center" id="app-count-decay"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page6">
                    <div className="page-content">
                        <p className="circles-font">
                            A group that uses knowledge and craft to unleash their skills through less volatile
                            means. They enchant their tools. Using things like potions, sigils, powders and spoken
                            word.
                        </p>
                    </div>
                </div>
                <div className="flip-page-odd" id="page7">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Sacrificial Circle</h1>
                        <img className="sac-image" alt='sacrificial' src={sac} />
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page8">
                    <div className="page-content">
                        <p className="circles-font">
                            Certain types of magic come with an ineffable cost. This group uses life or death to
                            produce the unspeakable. These types of magics will cause years of nightmares for even
                            the most brave souls.
                        </p>
                    </div>
                </div>
                <div className="flip-page-odd" id="page9">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Ignisari Warriors</h1>
                        <h1 className="text-xl text-center">Followers of Ferrarius</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page10">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In a land where fire dances and whispers, there dwelt a clan of tireless souls, devoted to the art of war. For them, the mere conjuring of flames would not suffice to bring kingdoms to their knees. Nay, they sought the marriage of fire and metal, a union from which birthed a machine of devastation.</p>
                            <p>These battlemages, masters of their craft, wielded molten metal and flame as their weapons, a fearsome force hurled upon their adversaries. In the span of mere days, they could lay waste to entire realms, sowing fields of metallic shards in the wake of their ruinous march.</p>
                            <p>Resolute in battle, these warriors closed upon their enemies with nigh unyielding tenacity, felling many who dared to defy them. And in the aftermath, the fallen would be found forever marked by the cooling touch of the metal that had been their undoing.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page11">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Cryomane Raiders</h1>
                        <h1 className="text-xl text-center">Followers of Is</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page12">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the frozen reaches of the North, where the air is crisp and the lands veiled in white, there dwell the followers of Is, artisans of ice. With skillful hands and chilling mastery, they forge weapons of frozen liquid, keen-edged and deadly, to strike down foes near and far.</p>
                            <p>These towering figures, a full stride taller than most men, inspire awe and dread when glimpsed upon the horizon. Yet, despite their stature, they are possessed of a swift and unerring grace, their movements as fluid and precise as the icy blades they wield.</p>
                            <p>The domain of snow and ice is their undisputed realm, and those who would trespass do so at their peril. Yet even beyond their frostbitten territories, they leave no trace of their passage, save for the cold embrace of death. As their weapons melt away like fleeting memories, the fallen lie in silent testament to the chilling might.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page13">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Stormcallers</h1>
                        <h1 className="text-xl text-center">Followers of Niyoltsoh</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page14">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the heart of the ancient forests, where the skies weep and the winds sigh, there resides a tribe that has mastered the art of the storm. With a potent blend of reverence and skill, they summon the very heavens, bending the winds to their will and forging lightning from the cloud-streaked skies.</p>
                            <p>The Stormcallers, as they are known, are a people of swift temper and unbending tradition. Those who venture into their lands are wise to heed their strictures, for the slightest misstep may invite the wrath of these volatile guardians. Tread lightly, traveler, for touching the wrong tree or approaching the sacred groves may bring a swift and fatal retribution.</p>
                            <p>Bound by ritual and ancient custom, the Stormcallers gather beneath the canopy to dance and sing, their voices and footfalls weaving the very fabric of the tempest. These nomads of the storm remain nestled within the forest's embrace, the boughs and roots offering sanctuary to their formidable power.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page15">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Balethorn Enclave</h1>
                        <h1 className="text-xl text-center">Followers of Bladona</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page17">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the impenetrable depths of the sylvan shadows, there dwell the enigmatic disciples of Bladona, a secretive sect steeped in the art of poisoncraft. Drawing upon the bounty of the forest, they harvest the deadly essence of flora, weaving a tapestry of venom and decay.</p>
                            <p>Their most potent toxins hold the power to dissolve the very flesh of their victims, reducing them to naught but a gruesome meld of bones and entrails in a fetid pool of ruin. Clad in thorned armor, these lethal acolytes stand as a testament to the danger lurking in their embrace – a single touch, and the kiss of death is sealed.</p>
                            <p>It is whispered that these followers of Bladona extend the reach of their domain by employing the remains of their prey to nourish the nascent saplings, creating a cycle of death and rebirth that empowers their ever-growing dominion.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page18">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Nightwraith Syndicate</h1>
                        <h1 className="text-xl text-center">Followers of Kage</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page19">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the realm of shadows, a clandestine clan dwells, veiled in whispers and dark myth. The Kage, as they are known, are an enigma to those who have heard of their existence, for few have encountered them and lived to recount the tale.</p>
                            <p>When the time comes for new members to join their ranks, entire villages vanish under the cloak of night, the youngest spirited away to be forged into unwavering loyalists. Employing sinister arts, they wield tinctures to lace their blades, noxious powders to suffocate unsuspecting chambers, and small explosives to create chaos and confusion in the heat of battle.</p>
                            <p>Like phantoms, they glide through the darkness, silent and elusive, striking with lethal precision before vanishing once more into the shadows from whence they came.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page20">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Darkbind Cabal</h1>
                        <h1 className="text-xl text-center">Followers of Hexe</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page21">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the shadows of the mundane world, a cabal of enigmatic figures gather, their meetings shrouded in secrecy and dark intent. The Darkbind Cabal, as they are whispered to be called, hold the power to twist the threads of fate, weaving a tapestry of doom that can alter the very course of societies and nations.</p>
                            <p>Unlike those who seek to uplift the world, this sinister assembly is said to orchestrate calamities on a grand scale, sowing the seeds of devastation without ever leaving the sanctum of their hidden chambers. Through the incantation of ancient words, the etching of powerful sigils, and the invocation of eldritch forces, these clandestine cultists hold the keys to a world of darkness and despair.</p>
                            <p>Their influence stretches far and wide, their true faces concealed by the veil of the ordinary. The Darkbind Cabal moves in silence, their malevolent work invisible to the eyes of the unsuspecting, as they continue to shape the shadows of a world on the brink.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page22">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Order of the Hemomancer</h1>
                        <h1 className="text-xl text-center">Followers of Martuk</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page23">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the heart of civilization, an age-old order endures, its roots stretching back to the very dawn of time. Hidden amongst the highest echelons of society, these enigmatic figures hold sway, guided by the arcane teachings of Martuk. It is through these ancient principles that they have learned to command the very lifeblood of their adversaries.</p>
                            <p>The followers of Martuk, by nature patient and deliberate, weave webs of slow ruin around their political, financial, and social enemies. To the untrained eye, their machinations manifest as seemingly natural afflictions, carefully orchestrated to bring about the downfall of the unwary.</p>
                            <p>Those who underestimate the power of this secretive order often find themselves facing a grim fate. In the end, the cost of their hubris is revealed, as their lifeless forms are discovered, drained of the very essence that once sustained them. The followers of Martuk leave behind only a chilling reminder of the deadly forces that lurk just beneath the surface of the world we know.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page24">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Spirit Walkers</h1>
                        <h1 className="text-xl text-center">Followers of Manis</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page25">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the twilight between realms, where the living and the dead intertwine, lies a power both potent and perilous. Crossing into the spirit realm has long been regarded as a formidable tool for those who dare to wield it, yet using such energies for nefarious purposes is strictly forbidden by the wise and the wary.</p>
                            <p>Undeterred by these constraints, the Manis followers have embraced the darkest corners of this otherworldly domain. They commune with vengeful spirits, enlisting their aid in carrying out heinous deeds and employing their unparalleled knowledge of the realms unseen.</p>
                            <p>To face the Manis followers in battle is to confront the very essence of terror. Beset by haunting apparitions, tormented by whispered revelations of one's darkest secrets, and stalked by shadowy figures that slip between realms, their adversaries find themselves ensnared in a waking nightmare. And as the cold steel of a dagger caresses their throat, they come to know the true horror of the Manis followers' wrath.</p>
                        </div>
                    </div>
                </div>
                <div className="flip-page-odd" id="page26">
                    <div className="page-content">
                        <h1 className="text-xl text-center">Deathweaver Coven</h1>
                        <h1 className="text-xl text-center">Followers of Nekros</h1>
                        <p className="mt text-center"></p>
                    </div>
                </div>
                <div className="flip-page-even" id="page27">
                    <div className="page-content">
                        <div className="lore-font">
                            <p>In the murky depths of the fetid wetlands, where the scent of death pervades the air, there lies the realm of the followers of Nekros. These practitioners of the forbidden arts possess the power to summon the dead from their eternal slumber, forging an undead legion no mortal army can withstand.</p>
                            <p>The sight of one's comrades falling in battle is a harrowing ordeal, but to witness their lifeless forms reanimated and set upon their former allies is an experience that haunts the soul forevermore. In the midst of this macabre dance, the once-familiar faces of friends and foes alike become the visage of dread.</p>
                            <p>As desperation takes hold, a grim strategy emerges: to sever the limbs of both ally and enemy, in a bid to halt the relentless advance of the Nekros' unholy throng. Yet even in this darkest hour, the true horror of the followers of Nekros remains, a malevolent force that lingers on the edge of nightmare.</p>
                        </div>
                    </div>
                </div>
            </HTMLFlipBook >
        </div >
    );
};

export default FlipBook;