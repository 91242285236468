import React, { useState, useEffect } from 'react';
import './GrimScroll.css';
import l1 from './images/grimscroll1.png';
import l2 from './images/grimscroll2.png';
import l3 from './images/grimscroll3.png';
import l4 from './images/grimscroll4.png';
import l5 from './images/grimscroll5.png';
import stars from './images/stars.png'

const GrimScroll = () => {
  const [grimTextColor, setGrimTextColor] = useState('black');

  useEffect(() => {
    let animationFrame;
    const handleScroll = () => {
      const grimSection = document.getElementById('grim-scroll');
      const triggerPosition = grimSection.getBoundingClientRect().top + window.innerHeight / 2;
      const value = window.scrollY;
      const scrollRange = 3000;

      const red = Math.min(255, Math.floor((value / scrollRange) * 255));
      const green = 0;
      const blue = 0;
      const color = `rgb(${red},${green},${blue})`;
      setGrimTextColor(color);

      // Ensure color is black when scrolling back up
      if (value < triggerPosition) {
        setGrimTextColor('black');
      }

      if (animationFrame === undefined) {
        animationFrame = requestAnimationFrame(updateAnimation);
      }
    };

    const updateAnimation = () => {
      let l1 = document.getElementById('layer1');
      let l2 = document.getElementById('layer2');
      let l3 = document.getElementById('layer3');
      let l4 = document.getElementById('layer4');
      let l5 = document.getElementById('layer5');
      let grim = document.getElementById('grim');
      let stars = document.getElementById('stars');

      if (l1 && l2 && l3 && l4 && l5) {
        let value = window.scrollY;
        stars.style.left = value * .25 + 'px';
        grim.style.top = value * 1.25 + 'px';
        l1.style.top = value * .5 + 'px';
        l2.style.top = value * .6 + 'px';
        l3.style.top = value * .7 + 'px';
        l4.style.top = value * .8 + 'px';
        l5.style.top = value * .9 + 'px';
      }
      animationFrame = requestAnimationFrame(updateAnimation);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <div className='scroll-container' id="scroll-cont">
      <div className="grim-scroll" id="grim-scroll">
        <section className="moving-parts">
          <div className="grim-text" id="grim" style={{ color: grimTextColor }}>
            GRIM
          </div>
          <img src={stars} id="stars" alt="starsLayer" className="responsive-img"></img>
          <img src={l5} id="layer5" alt="l5" className="responsive-img" />
          <img src={l4} id="layer4" alt="l4" className="responsive-img" />
          <img src={l3} id="layer3" alt="l3" className="responsive-img" />
          <img src={l2} id="layer2" alt="l2" className="responsive-img" />
          <img src={l1} id="layer1" alt="l1" className="responsive-img" />
        </section>
      </div>
    </div >
  );
};

export default GrimScroll;
