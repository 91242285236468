import React from 'react';
import './Footer.css';
import discordIcon from "./images/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"
import twitterIcon from "./images/2021 Twitter logo - blue.png"
import openSeaIcon from "./images/Logomark-Blue.png"

const Footer = () => {
    return (
        <footer className="footer">
            <a href="https://discord.gg/GM8k8Q3FqA" target="_blank" rel="noreferrer">
                <img src={discordIcon} alt="Join our Discord server" />
            </a>
            <a href="https://twitter.com/The_Grim_NFT" target="_blank" rel="noreferrer">
                <img src={twitterIcon} alt="Follow us on Twitter" />
            </a>
            <a href="https://opensea.io/collection/the-grim-genesis" target="_blank" rel="noreferrer">
                <img src={openSeaIcon} alt="Check out our OpenSea collection" />
            </a>
        </footer>
    );
};

export default Footer;