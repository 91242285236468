import React, { useState, useEffect } from 'react';
import './ClickableIcon.css';

const ClickableIcon = ({ image, onClick, dataBoxId }) => {
  const [clicked, setClicked] = useState(false);
  const [iconStyle, setIconStyle] = useState({});

  useEffect(() => {
    const randomPosition = () => {
      const position = Math.floor(Math.random() * 70) + 10; // Random position between 10% and 80%
      return position + '%';
    };

    const randomRotation = () => {
      const rotation = Math.floor(Math.random() * 360); // Random rotation between 0 and 360 degrees
      return rotation + 'deg';
    };

    setIconStyle({
      position: 'absolute',
      top: randomPosition(),
      left: randomPosition(),
      transform: `rotate(${randomRotation()})`
    });
  }, []);

  const handleClick = () => {
    setClicked(true);
    onClick(dataBoxId);
  };

  return (
    <img
      id={`icon-${dataBoxId}`}
      src={image}
      alt="Clickable Icon"
      className="clickable-icon"
      style={clicked ? { ...iconStyle, display: 'none' } : iconStyle}
      onClick={handleClick}
      data-box-id={dataBoxId}
    />
  );
};

export default ClickableIcon;
