import React, { useRef, useEffect } from 'react';
import p5 from 'p5';

const P5Background = ({ className, onReady }) => {
    const sketchRef = useRef();

    useEffect(() => {
        const sketch = (p5) => {
            // let canvas;
            let glorbs = []

            p5.setup = () => {
                const container = sketchRef.current;
                const width = container.clientWidth;
                const height = container.clientHeight;
                let canvas = p5.createCanvas(width, height);
                canvas.parent(sketchRef.current);
                canvas.addClass('p5-background-canvas');
                p5.background(0);
                p5.colorMode(p5.HSB, 360, 100, 100);
                let ranHueMin = p5.random(0, 340);
                let ranHueMax = ranHueMin + 20;
                let hue = p5.random(ranHueMin, ranHueMax);

                for (let i = 0; i < 300; i++) {
                    glorbs[i] = new Glorb(hue);
                }
                onReady(true);
            };

            p5.draw = () => {

                for (let i = 0; i < glorbs.length; i++) {
                    glorbs[i].tick();
                    glorbs[i].move();
                    glorbs[i].colorReset();
                }
            };

            p5.windowResized = () => {
                const container = sketchRef.current;
                const width = container.clientWidth;
                const height = container.clientHeight;
                p5.resizeCanvas(width, height);
            };

            class Glorb {
                constructor(hue) {
                    this.animAmount = 0;
                    this.animMulti = 40;
                    this.colorAnchor = 40;
                    this.hue = hue
                    this.reset();
                }

                reset() {

                    this.angle = p5.random(2, 10);
                    this.tight = p5.random(0.005, 0.05);
                    this.x = p5.random(p5.width * -1.2, p5.width * 1.2);
                    this.y = p5.random(p5.height * -1.2, p5.height * 1.2);
                    this.loc = p5.createVector(this.x, this.y);

                    this.animSpeed = p5.random(0.00001, 0.0005);
                };

                move() {
                    let a = p5.map(
                        p5.noise(this.loc.x * this.tight, this.loc.y * this.tight),
                        0,
                        1,
                        0,
                        this.angle
                    );
                    this.loc.add(p5.createVector(p5.cos(a), p5.sin(a)));
                };

                colorReset() {
                    if (this.animMulti > this.colorAnchor) {
                        this.animMulti -= 0.5;
                    }
                };

                tick() {
                    p5.noStroke();
                    p5.fill(this.hue, 90, this.animAmount * this.animMulti, this.animAmount * 100);
                    let w = this.animAmount * 20 + 1;
                    p5.ellipse(this.loc.x, this.loc.y, w);
                    this.animAmount += this.animSpeed;
                    if (this.animAmount > 1) {
                        this.animSpeed *= -1;
                    }
                    else if (this.animAmount < 0) {
                        this.reset();
                    }
                };
            }
        };

        new p5(sketch);
    }, [onReady]);

    return <div ref={sketchRef} className={className} />;
};

export default P5Background;