import React, { useState } from 'react';
import './GrimProtocolSection.css';
import ClickableIcon from './ClickableIcon';
import amuletIcon from './images/icons/AmuletIcon.png'
import beltIcon from './images/icons/BeltIcon.png'
import bootsIcon from './images/icons/BootsIcon.png'
import chestIcon from './images/icons/ChestIcon.png'
import glovesIcon from './images/icons/GlovesIcon.png'
import hatIcon from './images/icons/HatIcon.png'
import mainIcon from './images/icons/MainhandIcon.png'
import offIcon from './images/icons/OffhandIcon.png'
import pantsIcon from './images/icons/PantsIcon.png'
import ringIcon from './images/icons/Ring1Icon.png'
import ring2Icon from './images/icons/Ring2Icon.png'
import shoulderIcon from './images/icons/ShoulderIcon.png'
import champ from './images/Champ/Champion.png'
import hair from './images/Champ/Hair.png'
import hat from './images/Champ/Hat.png'
import shoulder from './images/Champ/Shoulder.png'
import chest from './images/Champ/Chest.png'
import gloves from './images/Champ/Gloves.png'
import belt from './images/Champ/Belt.png'
import pants from './images/Champ/Pants.png'
import boots from './images/Champ/Boots.png'
import ring from './images/Champ/Ring1.png'
import ring2 from './images/Champ/Ring2.png'
import amulet from './images/Champ/Amulet.png'
import main from './images/Champ/Mainhand.png'
import off from './images/Champ/Offhand.png'


const ProtocolSect = () => {
  const [dynamicText, setDynamicText] = useState(5);

  const handleIconClick = (boxId) => {
    const randomNumber = Math.random() * (1.5 - 0.5) + 0.5;
    const roundedRandomNumber = parseFloat(randomNumber.toFixed(2));
    setDynamicText((dynamicText) => {
      const newDynamicText = dynamicText + roundedRandomNumber;
      return parseFloat(newDynamicText.toFixed(2)); // Round the result again to 2 decimal places
    });

    const clickedIcon = document.getElementById(`icon-${boxId}`);
    if (clickedIcon) {
      const iconImage = clickedIcon.src;
      const box = document.getElementById(boxId);
      if (box) {
        box.style.backgroundImage = `url(${iconImage})`;
        clickedIcon.style.display = "none";
      }
      const equipmentImage = document.getElementsByClassName(`${boxId}-image`)[0];
      if (equipmentImage) {
        equipmentImage.style.visibility = equipmentImage.style.visibility === 'visible' ? 'hidden' : 'visible';
      }
    }
  };

  return (
    <div className="grim-protocol-section-container" id="protocol-section">
      <div className="gradient-container"></div>
      <h1 className="farming-basics-header">Farming Basics</h1>
      <div className="farming-description">
        <p>At the start the GRIM PFP will be the only way to obtain Villagers, the first Villager type being Farmers.</p>
        <p>Staking Farmers will allow you to play the Farming minigame.</p>
        <p>Craft randomized items that can have variable item quality.</p>
        <p>Item stats for Farming come in the form of "TPD" or Tokens Per Day.</p>
        <p>The better the items, the better the yield.</p>
        <p>Trade items or Farmers with other player to optimize yield.</p>
      </div>

      <div className="blah-box">
        <div className="left-side">
          <div className="click-stuff">Click Stuff</div>
          <div className="item-box">
            <ClickableIcon image={amuletIcon} onClick={handleIconClick} dataBoxId="amulet-box" />
            <ClickableIcon image={beltIcon} onClick={handleIconClick} dataBoxId="belt-box" />
            <ClickableIcon image={bootsIcon} onClick={handleIconClick} dataBoxId="boots-box" />
            <ClickableIcon image={chestIcon} onClick={handleIconClick} dataBoxId="chest-box" />
            <ClickableIcon image={glovesIcon} onClick={handleIconClick} dataBoxId="gloves-box" />
            <ClickableIcon image={hatIcon} onClick={handleIconClick} dataBoxId="hat-box" />
            <ClickableIcon image={mainIcon} onClick={handleIconClick} dataBoxId="main-box" />
            <ClickableIcon image={offIcon} onClick={handleIconClick} dataBoxId="off-box" />
            <ClickableIcon image={pantsIcon} onClick={handleIconClick} dataBoxId="pants-box" />
            <ClickableIcon image={ringIcon} onClick={handleIconClick} dataBoxId="ring-box" />
            <ClickableIcon image={ring2Icon} onClick={handleIconClick} dataBoxId="ring2-box" />
            <ClickableIcon image={shoulderIcon} onClick={handleIconClick} dataBoxId="shoulder-box" />
          </div>
        </div>
        <div className="right-side">
          <div className='container-right'>
            <div className="boxes-left">
              <div id="hat-box" className="hat-box"></div>
              <div id="shoulder-box" className="shoulder-box"></div>
              <div id="chest-box" className="chest-box"></div>
              <div id="gloves-box" className="gloves-box"></div>
              <div id="belt-box" className="belt-box"></div>
            </div>
            <h2 className="dynamic-text">Tokens Per Day = {dynamicText}</h2>
            <div className="image-container">
              <img src={champ} alt="Right-side-champ" className="right-side-champ" />
              <div className="image-container-items">
                <img src={hair} alt="Right-side-hair" className="right-side-hair" />
                <img src={hat} alt="Right-side-hat" className="right-side-hat hat-box-image" style={{ visibility: 'hidden' }} />
                <img src={chest} alt="Right-side-chest" className="right-side-chest chest-box-image" style={{ visibility: 'hidden' }} />
                <img src={gloves} alt="Right-side-gloves" className="right-side-gloves gloves-box-image" style={{ visibility: 'hidden' }} />
                <img src={boots} alt="Right-side-boots" className="right-side-boots boots-box-image" style={{ visibility: 'hidden' }} />
                <img src={pants} alt="Right-side-pants" className="right-side-pants pants-box-image" style={{ visibility: 'hidden' }} />
                <img src={belt} alt="Right-side-belt" className="right-side-belt belt-box-image" style={{ visibility: 'hidden' }} />
                <img src={ring} alt="Right-side-ring" className="right-side-ring ring-box-image" style={{ visibility: 'hidden' }} />
                <img src={ring2} alt="Right-side-ring2" className="right-side-ring2 ring2-box-image" style={{ visibility: 'hidden' }} />
                <img src={amulet} alt="Right-side-amulet" className="right-side-amulet amulet-box-image" style={{ visibility: 'hidden' }} />
                <img src={main} alt="Right-side-main" className="right-side-main main-box-image" style={{ visibility: 'hidden' }} />
                <img src={shoulder} alt="Right-side-shoulder" className="right-side-shoulder shoulder-box-image" style={{ visibility: 'hidden' }} />
                <img src={off} alt="Right-side-off" className="right-side-off off-box-image" style={{ visibility: 'hidden' }} />
              </div>
            </div>
            <div className="boxes-right">
              <div id="pants-box" className="pants-box"></div>
              <div id="boots-box" className="boots-box"></div>
              <div id="ring-box" className="ring-box"></div>
              <div id="ring2-box" className="ring2-box"></div>
              <div id="amulet-box" className="amulet-box"></div>
            </div>
            <div className="bottom-boxes">
              <div id="main-box" className="main-box"></div>
              <div id="off-box" className="off-box"></div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};


export default ProtocolSect;