import React from 'react';
import './App.css';
import GrimScroll from './GrimScroll';
import NavigationBar from './NavigationBar';
import IntroSect from './IntroSect';
import SummonSect from './SummonSect';
import PFPSect from './GrimPFPSection';
import ProtocolSect from './GrimProtocolSection';
import Prot2Sect from './GrimProtocol2'
import LoreSect from './GrimLoreSection';
import Footer from './Footer'
import './fonts/webfont.css';

function App() {

  return (
    <div className="App">
      <NavigationBar />
      <GrimScroll />
      <IntroSect />
      <SummonSect />
      <PFPSect />
      <ProtocolSect />
      <Prot2Sect />
      <LoreSect />
      <Footer />
    </div>
  );
}

export default App;

