import React, { useState, useCallback } from 'react';
import './GrimLoreSection.css';
import FlipBook from './FlipBook';
import openBook from './images/LoreBook/open_book.png';
import P5Background from './p5Background';



const LoreSect = () => {
  const [p5BackgroundReady, setP5BackgroundReady] = useState(false);
  const onP5BackgroundReady = useCallback((ready) => setP5BackgroundReady(ready), []);

  return (
    <div className="grim-lore-section-container" id="lore-section">
      <P5Background className="p5-background-container" onReady={onP5BackgroundReady} />
      <div className="book-image-container" id="book-img">
        <img src={openBook} alt="Open book" />
        {p5BackgroundReady && (
          <div className="flip-book-container">
            <FlipBook />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoreSect;