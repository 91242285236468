import React, { useState } from 'react';
import './SummonSect.css';
import ele from "./images/Elemental.png";
import dec from "./images/Decay.png";
import sac from "./images/Sacrificial.png";
import openseaIcon from "./images/Logomark-Transparent White.png"

const SummonSect = () => {
  const [activeCircle, setActiveCircle] = useState({
    image: ele,
    alt: "elemental",
    types: ["Fire", "Ice", "Lightning"],
  });

  const handleCircleClick = (circle) => {
    setActiveCircle(circle);
  };



  return (
    <div className={`summon-section-container ${activeCircle.alt}`} id="summon-sect">
      <h2>Summoning Circles: Early Entry</h2>
      <p>The Summoning Circles were an early entry into Grim. They have great benefits and can be obtained through giveaways in the discord or on the secondary market.</p>
      <div className="content-wrapper">
        <div className="circle-buttons-container">
          {['elemental', 'decay', 'sacrificial'].map((circle, index) => (
            <button
              key={index}
              onClick={() =>
                handleCircleClick({
                  image: circle === 'elemental' ? ele : circle === 'decay' ? dec : sac,
                  alt: circle,
                  types: circle === 'elemental' ? ['Fire', 'Ice', 'Lightning'] : circle === 'decay' ? ['Poison', 'Shadow', 'Curse'] : ['Black', 'Blood', 'Necromancy'],
                })
              }
            >
              <div className="button-inner">
                <span className="circle-name">
                  {circle === 'elemental'
                    ? 'The Elemental Circle'
                    : circle === 'decay'
                      ? 'The Circle of Decay'
                      : 'The Sacrificial Circle'}
                </span>
                {activeCircle.alt === circle && <span className="types-span">: {activeCircle.types.join(', ')}</span>}
              </div>
            </button>
          ))}
        </div>
        <div className="opensea-link-container">
          <a href="https://opensea.io/collection/the-grim-genesis" target="_blank" rel="noreferrer">
            <img src={openseaIcon} alt="Check out our OpenSea collection" />
          </a>
        </div>
        <div className="circle-content-wrapper">
          <div className="circle-image-container">
            <img
              src={ele}
              alt="elemental"
              className={`circle-bg ${activeCircle.alt === "elemental" ? "visible" : ""
                }`}
            />
            <img
              src={dec}
              alt="decay"
              className={`circle-bg ${activeCircle.alt === "decay" ? "visible" : ""
                }`}
            />
            <img
              src={sac}
              alt="sacrificial"
              className={`circle-bg ${activeCircle.alt === "sacrificial" ? "visible" : ""
                }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default SummonSect;
