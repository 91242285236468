import React from 'react';
import './IntroSect.css';


const Intro = () => {

    return (
        <div className="intro-section-container" id="intro-section">
            <div className="intro-text">
                <h1>The Grim Protocol</h1>
                <p>Introducing the Grim Protocol - a web gaming platform dedicated to interoperability. Our mission is to streamline Web3 integration for game developers, eliminating the need for Solidity contract expertise. We also provide gamers with a unique platform where "buying" a game translates into earning tokens and minting characters.</p>
            </div>
        </div>
    );
};


export default Intro;