import React, { useState, useEffect } from 'react';
import './NavigationBar.css';
import demonic from './images/Demonic.png'

const NavigationBar = () => {
  const [activeSection, setActiveSection] = useState('grim');
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const grimSection = document.getElementById('grim-scroll');
      const introSection = document.getElementById('intro-section');
      const circlesSection = document.getElementById('summon-sect');
      const pfpSection = document.getElementById('pfp-section');
      const protocolSection = document.getElementById('protocol-section');
      const protocol2Section = document.getElementById('prot2-section');
      const loreSection = document.getElementById('lore-section');

      const triggerPosition = window.innerHeight / 2;

      if (grimSection.getBoundingClientRect().bottom > triggerPosition) {
        setActiveSection('grim');
      } else if (introSection.getBoundingClientRect().top < triggerPosition && introSection.getBoundingClientRect().bottom > triggerPosition) {
        setActiveSection('intro');
      } else if (circlesSection.getBoundingClientRect().top < triggerPosition && circlesSection.getBoundingClientRect().bottom > triggerPosition) {
        setActiveSection('circles');
      } else if (pfpSection.getBoundingClientRect().top < triggerPosition && pfpSection.getBoundingClientRect().bottom > triggerPosition) {
        setActiveSection('pfp');
      } else if (protocolSection.getBoundingClientRect().top < triggerPosition && protocolSection.getBoundingClientRect().bottom > triggerPosition) {
        setActiveSection('protocol');
      } else if (protocol2Section.getBoundingClientRect().top < triggerPosition && protocol2Section.getBoundingClientRect().bottom > triggerPosition) {
        setActiveSection('prot2');
      } else if (loreSection.getBoundingClientRect().top < triggerPosition) {
        setActiveSection('lore');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleButtonClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setActiveSection(sectionId);
    }
  };

  return (
    <header className="navbar">
      <div className="navbar__logo">
        <img src={demonic} alt="Logo" />
      </div>
      <nav className={`navbar__links ${burgerMenuOpen ? 'open' : ''}`}>
        <button type="button" className={activeSection === 'grim' ? 'active' : ''} onClick={() => handleButtonClick('grim-scroll')}>GRIM</button>
        <button type="button" className={activeSection === 'intro' ? 'active' : ''} onClick={() => handleButtonClick('intro-section')}>Intro</button>
        <button type="button" className={activeSection === 'circles' ? 'active' : ''} onClick={() => handleButtonClick('summon-sect')}>Summoning Circles</button>
        <button type="button" className={activeSection === 'pfp' ? 'active' : ''} onClick={() => handleButtonClick('pfp-section')}>PFP</button>
        <button type="button" className={activeSection === 'protocol' ? 'active' : ''} onClick={() => handleButtonClick('protocol-section')}>Farming</button>
        <button type="button" className={activeSection === 'prot2' ? 'active' : ''} onClick={() => handleButtonClick('prot2-section')}>Protocol</button>
        <button type="button" className={activeSection === 'lore' ? 'active' : ''} onClick={() => handleButtonClick('lore-section')}>Lore</button>
      </nav>
      <button className="burger-menu" onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
  );
};

export default NavigationBar;
