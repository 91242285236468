import React from 'react';
import './GrimPFPSection.css';
import samp from './images/sampleWebPfP.png';
import firePFP from './images/FireImageForWebsite.png'
import icePFP from './images/IceImageWebsite.png'
import mcs from './images/morecomingsoon.png'
import portal from './images/purplePortal.png'


const PFPSect = () => {

  return (
    <div className="grim-pfp-section-container" id="pfp-section">
      <img className="portal-bg" alt='portalbg' src={portal}></img>
      <div className="content-container">
        <div className="text-container">
          <h2 className='grim-10k'>The 10K Mint</h2>
          <p className='grim-mint-desc'>The GRIM 5k PFP mint is designed with classic RPG and fantasy fiction elements. Almost all traits will be colored during generation. Certain traits will have many color layers, making traits shared with others unique. Owning 3 GRIM is the optimal strategy for token generation.</p>
        </div>
        <div className="pfp-image-scroll-container">
          <div className="pfp-image-container">
            <img src={firePFP} alt="PFP1" />
            <img src={icePFP} alt="PFP2" />
            <img src={mcs} alt="PFP3" />
            <img src={mcs} alt="PFP4" />
            <img src={mcs} alt="PFP5" />
            <img src={mcs} alt="PFP6" />
            <img src={mcs} alt="PFP7" />
            <img src={mcs} alt="PFP8" />
            <img src={mcs} alt="PFP9" />
          </div>
        </div>
      </div>
    </div >
  );
};

export default PFPSect;