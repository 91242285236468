import React from 'react';
import './GrimProtocol2.css';
import ArrowIcon from './ArrowIcon';

const Prot2 = () => {
  return (
    <div className="grim-prot2-section-container" id="prot2-section">
      <div className='protocol-text-wrapper'>
        <h2 className="protocol-text">Why is this important?</h2>
      </div>
      <div className="grid-wrapper">
        <div className="grid-container">
          <div className="interop-problem">
            <p>Game assets are often siloed within individual games or companies and rarely shared between different titles. This limits the potential for cross-game collaboration while increasing costs for players.</p>
          </div>
          <div className='arrow-icon1'>
            <ArrowIcon className='arrow-svg' color="rgba(246, 248, 221, 0.849)" />
          </div>
          <div className="interop-solution">
            <p>We are creating a Framework and Standard for characters and assets that can be used across our platform. This will reduce developer cost and required building time. For gamers, this increases asset usage while decreasing cost.</p>
          </div>
          <div className="cost-problem">
            <p>High transaction fees in NFT gaming can make it unsustainable. Even on networks like Polygon, on-chain game transaction fees can cost players up to $40,000 collectively per day. This is real money being burned instead of being used within the ecosystem.</p>
          </div>
          <div className='arrow-icon2'>
            <ArrowIcon className='arrow-svg' color="rgba(246, 248, 221, 0.849)" />
          </div>
          <div className="cost-solution">
            <p>By implementing our "Inventory" system to store Character and Item metadata, players decide when to mint ERC-721's or ERC-20's. This greatly reduces the transaction costs while increasing sustainability of the ecosystem. Wait to mint in batches, or only mint assets you want to trade.</p>
          </div>
          <div className="pay-to-win-problem">
            <p>Many P2E games have a "pay to win" scenario, players can "play" 100's of characters at the same time. This leads to a small group of whales accumulating most of the rewards, usually dumping them on all the other players.</p>
          </div>
          <div className='arrow-icon3'>
            <ArrowIcon className='arrow-svg' color="rgba(246, 248, 221, 0.849)" />
          </div>
          <div className="pay-to-win-solution">
            <p>To help prevent this, we have limited GRIM staking to 3 per wallet and Farmer staking to 1 per wallet. While the "pay to win" scenario can still hold true in some ways, whales will have to buy assets from other players. We have also taken precautions against multiple wallet scenarios by encouraging active participation.</p>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Prot2;